/*
 * action types
 */

export const BEFORE_LOGIN = "BEFORE_LOGIN";
export const LOGIN = "LOGIN";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const LOGOUT = "LOGOUT";
export const AFTER_LOGOUT = "AFTER_LOGOUT";
