import React from "react";
import PropTypes from "prop-types";
import { Marker } from "@react-google-maps/api";
import GInfoWindow from "../InfoWindow/GInfoWindow";

const GMarker = ({
  latlng,
  clickMarker,
  infoWindowContent = "",
  showInfoWindow
}) => {
  const markerOnLoad = marker => {
    // console.log("marker: ", marker);
  };
  const markerOnClick = e => {
    // console.log(showInfo);
    clickMarker(e);
  };

  return (
    <div>
      <Marker
        onLoad={markerOnLoad}
        position={{
          lat: latlng.lat,
          lng: latlng.lng
        }}
        onClick={markerOnClick}
      />
      {showInfoWindow && (
        <GInfoWindow latlng={latlng} content={infoWindowContent} />
      )}
    </div>
  );
};

GMarker.propTypes = {
  latlng: PropTypes.object.isRequired,
  clickMarker: PropTypes.func.isRequired,
  showInfoWindow: PropTypes.bool.isRequired,
  infoWindowContent: PropTypes.string.isRequired
};

export default GMarker;
