import * as ACTION_TYPES from "../actionTypes/gmap";

export const changeCenter = latlng => ({
  type: ACTION_TYPES.CHANGE_CENTER,
  latlng: latlng
});

export const changeZoom = zoomLevel => ({
  type: ACTION_TYPES.CHANGE_ZOOM,
  zoom: zoomLevel
});

export const changeBoundry = boundry => ({
  type: ACTION_TYPES.CHANGE_BOUNDRY,
  boundry: boundry
});
