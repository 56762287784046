import * as ACTION_TYPES from "../actionTypes/api";
import * as API from "../../api";
import lodash from "lodash";

export const getAllPois = () => {
  return dispatch => {
    // const accessToken = localStorage.getItem("access_token");
    API.getAllPois()
      .then(res => {
        // console.log(res.data);

        lodash.forEach(res.data, p => {
          let marker = {
            id: p.id,
            latlng: p.coordinate,
            infoWindow: false,
            infoWindowContent: p.name + " - " + p.address
          };
          dispatch(addMarker(marker));
        });

        dispatch(afterAllPois(res.data));
      })
      .catch(err => dispatch(errorAPI(err)));
  };
};

export const afterAllPois = pois => ({
  type: ACTION_TYPES.AFTER_ALL_POIS,
  pois: pois
});

export const addMarker = marker => ({
  type: ACTION_TYPES.ADD_MARKER,
  marker: marker
});

export const removeMarker = markerId => ({
  type: ACTION_TYPES.ADD_MARKER,
  markerId: markerId
});

export const errorAPI = err => ({
  type: ACTION_TYPES.API_ERROR,
  error: err
});
