import * as ACTION_TYPES from "../actionTypes/gmap";

const initialState = {
  center: { lat: 40, lng: 40 },
  zoom: 3
};
const gmapReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CHANGE_CENTER:
      return {
        ...state,
        center: {
          lat: action.latlng.lat,
          lng: action.latlng.lng
        }
      };
    case ACTION_TYPES.CHANGE_ZOOM:
      return {
        ...state,
        zoom: action.zoom
      };
    default:
      return state;
  }
};

export default gmapReducer;
