import * as ACTION_TYPES from "../actionTypes/api";

const initialState = {
  pois: [],
  markers: []
};
const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.AFTER_ALL_POIS:
      return {
        ...state,
        pois: action.pois
      };
    case ACTION_TYPES.ADD_MARKER:
      return {
        ...state,
        markers: [...state.markers, action.marker]
      };
    default:
      return state;
  }
};

export default apiReducer;
