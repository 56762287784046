import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login, logout } from "../../redux/actions/user";
import { getAllPois } from "../../redux/actions/api";
import { changeCenter, changeZoom } from "../../redux/actions/gmap";

const Home = ({ user, tryLogin, tryLogout, pois, getAllPois, zoomToPoi }) => {
  let inputUserName;
  return (
    <div>
      {!user.isOnline && (
        <form
          onSubmit={e => {
            e.preventDefault();
            if (!inputUserName.value.trim()) {
              return;
            }
            tryLogin(inputUserName.value);
            inputUserName.value = "";
          }}
        >
          <input ref={node => (inputUserName = node)} />
          <button type="submit">Login</button>
        </form>
      )}

      <p>{user.message}</p>

      {user.isOnline && (
        <button
          onClick={e => {
            tryLogout();
          }}
        >
          Logout
        </button>
      )}
      <br />
      {user.isOnline && pois.length === 0 && (
        <button
          onClick={e => {
            getAllPois();
          }}
        >
          Get Pois
        </button>
      )}

      {user.isOnline && pois.length > 0 && <h3>Poi List</h3>}

      {user.isOnline &&
        pois.map(p => (
          <p
            key={p.id}
            onClick={e => {
              zoomToPoi(p.coordinate);
            }}
          >
            {p.id} - {p.name}{" "}
          </p>
        ))}
    </div>
  );
};

Home.propTypes = {
  user: PropTypes.object.isRequired,
  /*
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
    isLoging: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
  }).isRequired,
  */
  tryLogin: PropTypes.func.isRequired,
  tryLogout: PropTypes.func.isRequired,

  pois: PropTypes.array.isRequired,
  getAllPois: PropTypes.func.isRequired,
  zoomToPoi: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  pois: state.data.pois
});

const mapDispatchToProps = dispatch => ({
  tryLogin: username => dispatch(login(username)),
  tryLogout: () => dispatch(logout()),

  getAllPois: recs => dispatch(getAllPois(recs)),
  zoomToPoi: coords => {
    dispatch(changeCenter(coords));
    dispatch(changeZoom(19));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
