import React from "react";
import { Link } from "react-router-dom";

import { ROUTER_HEADERS } from "../../constants/index";

export default function AppRouterHeader() {
  return (
    <nav>
      <ul>
        <li>
          <Link to={ROUTER_HEADERS.HOME.PATH}>{ROUTER_HEADERS.HOME.TITLE}</Link>
        </li>
      </ul>
    </nav>
  );
}
