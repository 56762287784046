import React from "react";
import PropTypes from "prop-types";
import { InfoWindow } from "@react-google-maps/api";

const GInfoWindow = ({ latlng, content }) => {
  const infoWindoeOnLoad = infoWindow => {
    console.log("infoWindow: ", infoWindow);
  };

  return (
    <InfoWindow
      onLoad={infoWindoeOnLoad}
      position={{
        lat: latlng.lat,
        lng: latlng.lng
      }}
    >
      <div
        style={{
          background: `white`,
          border: `1px solid #ccc`,
          padding: 10
        }}
      >
        <h4>{content}</h4>
      </div>
    </InfoWindow>
  );
};

GInfoWindow.propTypes = {
  latlng: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired
};

export default GInfoWindow;
