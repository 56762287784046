import * as ACTION_TYPES from "../actionTypes/user";
import * as API from "../../api";

export const beforeLogin = username => ({
  type: ACTION_TYPES.BEFORE_LOGIN,
  username: username
});

export const successLogin = (username, accessToken) => ({
  type: ACTION_TYPES.SUCCESS_LOGIN,
  username: username,
  accessToken: accessToken
});

export const errorLogin = err => ({
  type: ACTION_TYPES.ERROR_LOGIN,
  error: err
});

export const login = username => {
  return dispatch => {
    dispatch(beforeLogin(username));

    API.login(username)
      .then(res => {
        localStorage.setItem("username", username);
        localStorage.setItem("access_token", res.data.access_token);
        dispatch(successLogin(username, res.data.access_token));
      })
      .catch(err => dispatch(errorLogin(err)));
  };
};

export const logout = () => {
  return dispatch => {
    localStorage.removeItem("username");
    localStorage.removeItem("access_token");

    dispatch(afterLogout());
  };
};

export const afterLogout = () => ({
  type: ACTION_TYPES.AFTER_LOGOUT
});
