import * as ACTION_TYPES from "../actionTypes/user";

const initialState = {
  username: "",
  accessToken: "",
  isLoging: false,
  isOnline: false,
  message: ""
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.BEFORE_LOGIN:
      return {
        ...state,
        isLoging: true,
        message: "Please wait..."
      };
    case ACTION_TYPES.SUCCESS_LOGIN:
      return {
        ...state,
        username: action.username,
        accessToken: action.accessToken,
        isLoging: false,
        isOnline: true,
        message: "Wellcome " + action.username
      };
    case ACTION_TYPES.ERROR_LOGIN:
      return {
        ...state,
        isLoging: false,
        message: "Error: " + action.error
      };
    case ACTION_TYPES.AFTER_LOGOUT:
      return {
        ...state,
        username: "",
        accessToken: "",
        isOnline: false,
        message: ""
      };
    default:
      return state;
  }
};

export default userReducer;
