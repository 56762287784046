import React, { Component } from "react";
import AppRouter from "../router/AppRouter";

class LeftPane extends Component {
  componentDidMount() {
    //console.log("LeftPane component loaded.");
  }

  render() {
    return (
      <div id="left-page" className="left-page">
        <AppRouter />
      </div>
    );
  }
}

export default LeftPane;
