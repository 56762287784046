import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { ROUTER_HEADERS } from "../../constants/index";
import AppRouterHeader from "./AppRouterHeader";
import Home from "../../pages/home/Home";

function AppRouter() {
  return (
    <div id="app-router" key="app-router">
      <Router>
        <AppRouterHeader />
        <Route path={ROUTER_HEADERS.HOME.PATH} exact component={Home} />
      </Router>
    </div>
  );
}

export default AppRouter;
