import React, { Component } from "react";

import LeftPane from "../LeftPane/LeftPane";
import GoogleMaps from "../GoogleMaps/GoogleMaps";

class App extends Component {
  render() {
    return (
      <div className="app">
        <LeftPane />
        <GoogleMaps />
      </div>
    );
  }
}

export default App;
