import React, { Component } from "react";
import PropTypes from "prop-types";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { connect } from "react-redux";
import { changeCenter, changeZoom } from "../../redux/actions/gmap";
import GMarker from "./Marker/GMarker";

class GoogleMaps extends Component {
  googleMaps = null;

  onZoomChanged = (...args) => {
    // console.log(this.googleMaps.zoom);
    this.props.zoomChanged(this.googleMaps.zoom);
  };

  onCenterChanged = (...args) => {
    const pcenter = {
      lat: this.googleMaps.center.lat(),
      lng: this.googleMaps.center.lng()
    };
    this.props.centerChanged(pcenter);
  };

  onMapLoad = map => {
    // console.log("map.data: ", map.data);
    this.googleMaps = map;
  };

  onClick = (...args) => {
    console.log(
      "onClick args: ",
      args[0].latLng.lat(),
      " : ",
      args[0].latLng.lng()
    );
    console.log(this.props.gmap.center);
    console.log(this.props.gmap.zoom);
  };

  onDataLoad = data => {
    console.log("data: ", data);
  };

  render() {
    return (
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyCBXnMt4w3WZKg6xXcOPf5T3K1RHOpmhMg"
      >
        <div className="map-container">
          <GoogleMap
            id="tripian-map"
            zoom={this.props.gmap.zoom}
            center={this.props.gmap.center}
            onZoomChanged={this.onZoomChanged}
            onCenterChanged={this.onCenterChanged}
            onClick={this.onClick}
            onLoad={this.onMapLoad}
          >
            {this.props.markers.map(m => (
              <GMarker
                key={m.id}
                latlng={m.latlng}
                clickMarker={e => console.log(m.id)}
                showInfoWindow={m.infoWindow}
                infoWindowContent={m.infoWindowContent}
              />
            ))}
          </GoogleMap>
        </div>
      </LoadScript>
    );
  }
}

GoogleMaps.propTypes = {
  gmap: PropTypes.object.isRequired,
  markers: PropTypes.array.isRequired,
  zoomChanged: PropTypes.func.isRequired,
  centerChanged: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  gmap: state.gmap,
  markers: state.data.markers
});

const mapDispatchToProps = dispatch => ({
  centerChanged: platlng => dispatch(changeCenter(platlng)),
  zoomChanged: pzoom => dispatch(changeZoom(pzoom))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleMaps);
