import axios from "axios";

const apiUrl = "https://uqo7xnd7f9.execute-api.eu-west-1.amazonaws.com/v2/";
const xApiKey = "PytGowqA5B65c7YMp3qf98eWyIotGLbn2uOvG7m8";

export const login = (username = "") => {
  return axios
    .post(
      apiUrl + "login",
      { username: username },
      {
        headers: {
          "x-api-key": xApiKey
        }
      }
    )
    .catch(err => {
      // console.error(err);
      return Promise.reject(err);
    })
    .then(res => {
      // console.log(res);
      return res.data;
    });
};

let defaultparamsObject = {
  city_id: 159,
  poi_categories: 3
};
export const getAllPois = (paramsObject = defaultparamsObject) => {
  return axios
    .get(apiUrl + "poi/", {
      headers: {
        "x-api-key": xApiKey
      },
      params: paramsObject
    })
    .catch(err => {
      // console.error(err);
      return Promise.reject(err);
    })
    .then(res => {
      // console.log(res);
      return res.data;
    });
};
